import createThemeVars, { createMap } from "@fckdtl/themotion/helpers/cssVars"; // TODO: should not be used outside of umbrella

const config = {
  domain: "color",
  createCssVariables: true, // default false
  createUtilClasses: { prefix: "c-" }, // TODO: generate them
  scope: [
    "background-color",
    "border-color",
    "box-shadow",
    "color",
    "text-shadow",
  ], // TODO: does this make sense?
  allowTokenAccess: false, // Should there be vars generated for the tokens, too?
};
const tokens = {
  purple: "#4818F4",
  "limb-purple": "#6952bc",
  // green: "#03DAC6",
  green: "#00deca",
  "limb-green": "#1c756d",
  black: "#212121",
  "dark-black": "#000000",
  dirtywhite: "#F5F5F5",
  white: "#ffffff",
};

const sets = {
  default: {
    spot1: "purple",
    spot2: "green",
    text: "black",
    bg: "white",
    bg2: "dirtywhite",
    // error: "",
    // warning: "",
    // disabled: "",
  },
  dark: {
    spot1: "limb-purple",
    spot2: "limb-green",
    text: "dirtywhite",
    bg: "black",
    bg2: "dark-black",
    // error: "",
    // warning: "",
    // disabled: "",
  },
};

const themes = sets;

// from here on the rest should happen automatically in the theme factory
// TODO: do the next by a createTheme function

const vars = createThemeVars(config.domain, themes, tokens);

// used for getting values
const map = createMap(vars);

const colors = {
  config,
  tokens,
  themes,
  vars,
  map,
};

export default colors;
