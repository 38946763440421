/** @jsx jsx */
import { css, jsx, withTheme } from "@fckdtl/themotion/react";

import Grid from "./layout/Grid";
import Row from "./layout/Row";
import Cell from "./layout/Cell";
import { H4, Caption } from "./texts";
import Button from "./Button";

const Footer = withTheme(({ theme }) => (
  <Grid
    as="footer"
    css={css(`
      background-color: $color-bg2;
    `)(theme)}
    className="pt-14"
  >
    <Row>
      <Cell
        span={{ default: 10 / 12, md: 5 / 12, x2l: 3 / 12 }}
        offset={{ default: 1 / 12 }}
        css={css(`order: 0;`)}
        className="mb-14"
      >
        <H4>Eatery Berlin</H4>
        <Caption>
          Ben Donath
          <br />
          T: +49 176 63 05 47 81
          <br />
          E: post@eateryberlin.com
        </Caption>
      </Cell>
      <Cell
        span={{ default: 10 / 12, md: 5 / 12, x2l: 3 / 12 }}
        offset={{ default: 1 / 12, x2l: 0 }}
        css={css(`order: 2;`)}
        className="mb-14"
      >
        <H4>Social Media</H4>
        <Caption>
          Facebook
          <br />
          Instagram
          <br />
          Pinterest
          <br />
          Twitter
          <br />
          YouTube
        </Caption>
      </Cell>
      <Cell
        span={{ default: 10 / 12, md: 5 / 12, x2l: 2 / 12 }}
        offset={{ default: 1 / 12, md: 0 }}
        css={css(`
          order: 2;
        `)}
        className="mb-14"
      >
        <H4>Legal</H4>
        <Caption>
          Impressum
          <br />
          Datenschutz
          <br />
          Kontakt
        </Caption>
      </Cell>
      <Cell
        span={{ default: 10 / 12, md: 5 / 12, x2l: 2 / 12 }}
        offset={{ default: 1 / 12, md: 0 }}
        css={css(`
          order: 1;
          @media:x2l {
            order: 2;
          }
        `)}
        className="mb-14"
      >
        <Button to="/kontakt">Anfrage stellen</Button>
        <div css={css(`display: flex; justify-content: space-between;`)(theme)}>
          <Caption as="span">Language</Caption>
          <div>
            <Button to="/kontakt" small>
              Deutsch
            </Button>
            <Button to="/kontakt" small>
              English
            </Button>
          </div>
        </div>
      </Cell>
    </Row>
  </Grid>
));

export default Footer;
