/** @jsx jsx */
import { jsx, css, withTheme } from "@fckdtl/themotion/react";

const components = [
  { name: "P", tag: "p", cls: "$text $textBody $textBody1" },
  { name: "H1", tag: "h1", cls: "$text $textHeadline $textH1" },
  { name: "H2", tag: "h2", cls: "$text $textHeadline $textH2" },
  { name: "H3", tag: "h3", cls: "$text $textHeadline $textH3" },
  { name: "H4", tag: "h4", cls: "$text $textHeadline $textH4" },
  { name: "H5", tag: "h5", cls: "$text $textHeadline $textH5" },
  { name: "H6", tag: "h6", cls: "$text $textHeadline $textH6" },
  { name: "Overline", tag: "h3", cls: "$text $textOverline" },
  { name: "Subtitle1", tag: "h3", cls: "$text $textSubtitle1" },
  { name: "Caption", tag: "p", cls: "$text $textCaption" },
];

const createComponent = ({ tag, cls }) =>
  withTheme(({ theme, children, as: Tag = tag, ...props }) => (
    <Tag css={css(cls)(theme)} {...props}>
      {children}
    </Tag>
  ));

const {
  P,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Overline,
  Subtitle1,
  Caption,
} = components.reduce((acc, cur) => {
  const Comp = createComponent(cur);
  Comp.displayName = cur.name;
  return { ...acc, [cur.name]: Comp };
}, {});
export { P, H1, H2, H3, H4, H5, H6, Overline, Subtitle1, Caption };
