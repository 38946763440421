/** @jsx jsx */
import PropTypes from "prop-types";
import { css, jsx, withTheme } from "@fckdtl/themotion/react";

const roundNumber = (value) => Math.floor(value * 100 * 100) / 100;

const Cell = ({ theme, children, as: Tag = "div", offset, span, ...props }) => (
  <Tag
    css={css(`
  box-sizing: border-box;
  padding: calc($grid-gutter / 2);
  flex-grow: unset;
  ${(() => {
    // span rules
    const breakpointsInUse = Object.keys(span);
    const generateRule = (v) => `width: ${v}%;`;
    let rules = "";
    if (!breakpointsInUse.includes("default")) {
      // default rule for smallest size
      rules += generateRule(100);
    }
    breakpointsInUse.forEach((k) => {
      const v = span[k];
      if (k === "default") {
        rules += generateRule(roundNumber(v));
      } else {
        rules += `@media:${k} { ${generateRule(roundNumber(v))} }`;
      }
    });
    return rules;
  })()}
  ${(() => {
    // offset rules
    const breakpointsInUse = Object.keys(offset);
    const generateRule = (v) => `margin-left: ${v}%;`;
    let rules = "";
    breakpointsInUse.forEach((k) => {
      const v = offset[k];
      if (k === "default") {
        rules += generateRule(roundNumber(v));
      } else {
        rules += `@media:${k} { ${generateRule(roundNumber(v))} }`;
      }
    });

    return rules;
  })()}
`)(theme)}
    {...props}
  >
    {children}
  </Tag>
);
// {
//   return <div>{children</div>
//     ({ theme }) =>
//       css(`
//     box-sizing: border-box;
//     padding: calc($grid-gutter / 2);
//     flex-grow: unset;
//   `)(theme)
// ({ span, theme }) => {
//   // span rules
//   const breakpointsInUse = Object.keys(span);
//   const firstBreakpointAlias = theme.breakpointAlias[0];
//   const generateRule = (v) => `width: ${v}%;`;
//   let rules = "";
//   if (!breakpointsInUse.includes(firstBreakpointAlias)) {
//     // default rule for smallest size
//     rules += generateRule(100);
//   }
//   breakpointsInUse.forEach((k) => {
//     const v = span[k];
//     if (k === firstBreakpointAlias) {
//       rules += generateRule(roundNumber(v));
//     } else {
//       rules += mediaquery(k, generateRule(roundNumber(v)))(theme);
//     }
//   });
//   return css(rules);
// },
// ({ offset, theme }) => {
//   // offset rules
//   const breakpointsInUse = Object.keys(offset);
//   const firstBreakpointAlias = theme.breakpointAlias[0];
//   const generateRule = (v) => `margin-left: ${v}%;`;
//   let rules = "";
//   breakpointsInUse.forEach((k) => {
//     const v = offset[k];
//     if (k === firstBreakpointAlias) {
//       rules += generateRule(roundNumber(v));
//     } else {
//       rules += mediaquery(k, generateRule(roundNumber(v)))(theme);
//     }
//   });

//   return rules;
// };
//   )
// );
Cell.propTypes = {
  children: PropTypes.node,
  span: PropTypes.objectOf(PropTypes.number),
  offset: PropTypes.objectOf(PropTypes.number),
};
Cell.defaultProps = {
  children: null,
  span: {
    sm: 1,
  },
  offset: {
    sm: 0,
  },
};

export default withTheme(Cell);
