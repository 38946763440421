import createThemeVars, { createMap } from "@fckdtl/themotion/helpers/cssVars";

import { baseline } from "../config";

const config = {
  domain: "grid",
  createCssVariables: true,
};
const tokens = {
  gutter: `{${2 * baseline},${2 * baseline},,,,${
    5 * baseline
  }}({"step": ${baseline}})px`,
};
const themes = {
  default: {
    gutter: "gutter",
    // cols: "cols",
    // offset: "offset",
  },
};
const vars = createThemeVars(config.domain, themes, tokens);

// used for getting values
const map = createMap(vars);

const grid = {
  config,
  tokens,
  themes,
  vars,
  map,
};

export default grid;
