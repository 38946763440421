/** @jsx jsx */
import { css, jsx, withTheme } from "@fckdtl/themotion/react";

const Button = withTheme(
  ({ theme, children, as: Tag = "button", small, ...props }) => (
    <Tag
      css={css(`
    $layerBorder;
    $textButton;
    background: transparent;
    box-sizing: border-box;
    display: inline-block;
    color: $color-spot1;
    padding: 15px 27px;
    &:focus {
      outline: 0;
      box-shadow: 0 0 8px rgba(0,222,202, 0.6);
    }

    ${() =>
      !small &&
      `
      @media screen and (min-width: 768px) {
          padding: 27px 47px;
      }`}
  `)(theme)}
      {...props}
    >
      {children}
    </Tag>
  )
);

export default Button;
