import createThemeVars, { createMap } from "@fckdtl/themotion/helpers/cssVars";

const config = {
  domain: "font",
  createCssVariables: true,
  fonts: {
    headline: {
      top: 0.2375,
      bottom: 0.75,
    },
    copy: {
      top: 0.3375,
      bottom: 0.86875,
    },
    mono: {
      top: 0.3875,
      bottom: 0.86875,
    },
  },
};
const tokens = {
  headline: `"Headline", serif`,
  copy: `"Copy", sans-serif`,
  mono: `"Mono", monospace`,
};

const theme = {
  default: {
    headline: "headline",
    subline: "mono",
    text: "copy",
    interface: "mono",
    buttons: "headline",
  },
};

// from here on magic should happen
// TODO: do the next by a createTheme function

const vars = createThemeVars(config.domain, theme, tokens);

const map = createMap(vars);

const fonts = {
  config,
  tokens,
  theme,
  vars,
  map,
};

export default fonts;
