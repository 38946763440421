/** @jsx jsx */
import { css, jsx, withTheme } from "@fckdtl/themotion/react";

const Row = withTheme(({ theme, children, as: Tag = "div", ...props }) => (
  <Tag
    css={css(`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: calc($grid-gutter / -2);
`)(theme)}
    {...props}
  >
    {children}
  </Tag>
));

export default Row;
