/** @jsx jsx */
import { css, jsx, withTheme } from "@fckdtl/themotion/react";
import { Link } from "gatsby";

import { H4, Subtitle1 } from "./texts";
import Cell from "./layout/Cell";
import Grid from "./layout/Grid";
import Row from "./layout/Row";
import { StaticImage } from "gatsby-plugin-image";
// import { H2 } from "./texts";

const StyledLink = ({ children, ...props }) => (
  <li
    css={css(`
    display: none;
    @media:xl {
      display: flex;
      align-items: baseline;
    }
  `)}
  >
    <Subtitle1
      as={Link}
      // css={css(``)}
      className="p-2"
      {...props}
    >
      {children}
    </Subtitle1>
  </li>
);

// const Li = ({chidlren, ...props}) => {
//   <li>{children}</li>
//   + StyledLink {
//     &:before {
//       content: "|";
//     }
//   }
// }

const Header = withTheme(({ theme, ...props }) => (
  <header
    css={css(`
      position: relative;
      z-index: 9999;
      @media:xl {
        position: fixed;
        right: 0;
        top: 0;
        left: 80px;
        min-height: 80px;
        background: var(--color-bg);
      }
      &:before {
        content: "";
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 10px;
        height: calc(100vh + 40px);
        background-color: $color-spot2;

        @media:xl {
          width: 80px;
        }
      }

      a {
        display: block;
        color: var(--color-spot1);
        text-decoration: none;
      }

      li + li + li {
        &:before {
          content: "|";
          color: var(--color-spot1);
        }
      }
    `)(theme)}
    {...props}
  >
    <Grid css={css(`position: relative;`)}>
      <Row>
        <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
          <ul
            css={css(`
              position: relative;
              top: 22px;
              left: 0;
              list-style: none;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: baseline;

              > li {
                margin: 0;
                padding: 0;
              }
          `)}
          >
            <li>
              <H4 css={css(`margin: 0;`)}>
                <Link to="/">
                  <StaticImage
                    src="../images/logo.png"
                    placeholder="none"
                    layout="fixed"
                    quality="100"
                    alt=""
                    css={css(`
                    display: inline-block;
                    margin: -10px 5px -10px 0;
                    width: 39px !important;
                    height: 39px !important;

                    @media:xl {
                      position: fixed;
                      left: 14px;
                      top: 30px;
                      width: 52px !important;
                      height: 52px !important;
                    }`)}
                  />{" "}
                  Eatery Berlin
                </Link>
              </H4>
            </li>
            <StyledLink to="/">About</StyledLink>
            <StyledLink to="/">Leistungen</StyledLink>
            <StyledLink to="/">Rezepte</StyledLink>
            <StyledLink to="/">Stories</StyledLink>
            <StyledLink to="/">Kontakt</StyledLink>
          </ul>
        </Cell>
      </Row>
    </Grid>
  </header>
));

export default Header;
