/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import ThemeProvider from "@fckdtl/themotion/ThemeProvider";
import CssBase from "@fckdtl/themotion/CssBase";
import CssUtils from "@fckdtl/themotion/CssUtils";

import Header from "./Header";
import Footer from "./Footer";

import "../styles/fonts/fonts.scss";
import theme from "../styles/theme";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <CssBase />
      <Header />
      <main>{children}</main>
      <Footer />
      <CssUtils />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
