import createThemeVars, { createMap } from "@fckdtl/themotion/helpers/cssVars"; // TODO: should not be used outside of umbrella

const config = {
  domain: "breakpoint",
  type: "breakpoint",
  createCssVariables: false, // default false
};

const tokens = {
  default: 0,
  sm: 480,
  md: 640,
  lg: 768,
  xl: 1024,
  x2l: 1280,
  x3l: 1440,
  x4l: 1600,
  x5l: 1920,
};

// const breakpointValues = Object.values(breakpoint);
// const breakpointAliases = Object.keys(breakpoint);

const themes = {
  default: {
    ...tokens,
  },
};

// from here on the rest should happen automatically in the theme factory
// TODO: do the next by a createTheme function

// const vars = createThemeVars(config.domain, themes, tokens);

// console.log(vars);

// used for getting values
// const map = createMap(vars);

const breakpoints = {
  config,
  themes,
  map: {
    ...tokens,
  },
};

export default breakpoints;
